//首页 Landing
export const Landing = {
  Recharge: '立即充值',
  chooseGame: '请选择您要充值的游戏',
  enterGameName: '请输入游戏名称',
  gameList: '游戏列表',
  enter: '立即进入',
  more: '查看更多',
  problem: '为什么要使用游戏支付渠道来充值?',
  answer1: '1.限时活动:丰富多彩的活动，限时开启',
  answer2: '2.VIP特权:享受专属VIP特权，获取更多的游戏道具奖励',
  answer3: '3.专属客服:享受专属的VIP客服，为您排忧解难',
  answer4: '4.优惠活动:不定时的优惠券活动，获取更多的平台金币或以更优惠的价格购买游戏道具',
  answer5: '5.更多的充值方式:多种充值方式，让您充值无忧',
  answer1_title: '限时活动',
  answer2_title: 'VIP特权',
  answer3_title: '专属客服',
  answer4_title: '优惠活动',
  answer5_title: '更多的充值方式',
  answer1_dec: '丰富多彩的活动，限时开启',
  answer2_dec: '享受专属VIP特权，获取更多的游戏道具奖励',
  answer3_dec: '享受专属的VIP客服，为您排忧解难',
  answer4_dec: '不定时的优惠券活动，获取更多的平台金币或以更优惠的价格购买游戏道具',
  answer5_dec: '多种充值方式，让您充值无忧',
  close: '关闭',
  allGame: '所有游戏'
}

//底部信息 Footer
export const Footer = {
  mgolFooter: '提供G1在线游戏服务的许可证编号：283/GP-BTTTT，日期为2023年8月7日',
  mocFooter: '提供G1网络游戏服务的许可证编号：463/GP-BTTTT，日期为2019年10月28日',
  address: '地址:'
}

//导航栏信息 info
export const Info = {
  return: '返回首页',
  store: '积分商店',
  order: '订单',
  unlock: '解封',
  exit: '退出',
  cancel: '取消',
  napProblem:'充值问题'
}

//登录页 Login
export const Login = {
  gameId: '账号登录',
  or: '或者使用',
  regAccount: '注册账户',
  emailAccount: 'Email帐户',
  phoneAccount: 'phone账户',
  password: '密码',
  confirm: '确认',
  enterEmail: '请输入您的电子邮件',
  enterCode: '请输入验证码',
  enterPassword: '请输入您的密码',
  enterPasswordAgain: '请重新输入你的密码',
  retrunLogin: '返回登录',
  enterTrueEmail: '请输入正确的邮箱账号！',
  enterNumPassword: '请输入包含字母和数字的6-20个字符的密码',
  differentPassword: '两次输入的密码不相同！',
  sendCode: '发送验证码',
  enterAccount: '请输入您的帐号信息',
  enterTrueEmail: '请输入正确的电子邮件',
  enterTruePhone: '请输入正确的电话号码',
  loginSuccess: '登录成功',
  resend: '重新发送',
  reg: '注册',
}


//详情页 consume
export const consume = {
  noData: '暂无数据',
  gameId: '选择角色',
  chooseServer: '选择服务器',
  chooseRole: '选择角色',
  Server: '服务器：',
  roleId: '角色ID：',
  roleName: '角色名：',
  chooseOtherRole: '选择其他角色',
  ChooseCombo: '选择商品',
  returen: '返回：',
  choosePayMethod: '选择付款方式',
  remaining: '剩余',
  extraGift: '额外赠送',
  next: '下一个',
  confirmInfo: '确认信息',
  pay: '支付',
  selectCoupon: '选择优惠券',
  canUseCoupon: '{num} 优惠券',
  notUse: '无',
  price: '价格',
  btnRemark: '单击“立即付款”按钮，即表示您同意此交易不可退款且不可取消。',
  limitedTimeEvent: '“限时有 {num} 次免费参与时间（可以充值的最高硬币），使用优惠券不能参加活动”',
  currency: '货币',
  special: '特别',
  enterAccount: '请输入您的帐号信息',
  enterPassword: '请输入密码',
  step1: '步骤1/4：游戏ID账号',
  step2: '步骤2/4：选择套餐',
  step3: '步骤3/4：选择付款方式',
  step4: '步骤4/4：确认信息',
  step2_1: '步骤1/3：选择国家',
  step3_1: '步骤2/3：选择付款方式',
  step4_1: '步骤3/3：确认信息',
  insufficientBalance: '余额不足',
  MinDeposit: '最低支付金额 {money}',
  validityPeriod: '有效期 ',
  availableDates: '可用日期 ',
  deliver1: '满{money}USD送{gift}Coin',
  deliver2: '满{money}USD减{gift}%',
  deliver3: '满{money}USD减{gift}',
  paySuccess: '支付成功',
  revise: '修改',
  enterCard: '输入卡代码',
  cardRecharge: '卡充值',
  channelRebate: '渠道返利：',
  VIPRebate: 'VIP返利：',
  couponRebate: '优惠劵返利：',
  totalRebate: '总返利金额：',
  remainingNum: '剩余 {num} 次',
  limitNum: '今天仅限使用 {num} 次',
  notUseCoupon: '没有可用的优惠券',
  reselect: '当前优惠券无法用于此付款方式，请重新选择！',
  channel: '付款渠道：',
  use: "使用",
  chooseCountry:'选择国家',
  payCountry:'支付地区',
  napRemark:'充值过程中，如遇任何问题可通过顶部"充值问题"按钮进行反馈。',
  autoLogin:'购买代金券',
  maintain:'维护中，请使用其他充值方式',
}

//AtmResult 和 purchaseResult
export const result = {
  paySuccess: '支付成功',
  payFail: '支付失败',
  orderNo: '订单号',
  payMoney: '支付金额',
  giftAmount: '赠送金额',
  return: '返回'
}

//首页头 page
export const page = {
  enterGameName: '请输入游戏名称',
}

//gate
export const gate = {
  serialNumber: '序列号：',
  enterSerialNumber: '输入序列卡号：',
  cardNumber: '卡号',
  enterCardNumber: '输入卡号',
  topUp: '确定',
  topUpSuccess: '充值成功',
}

//unlock
export const unlock = {
  confirm: '确定',
}

//store
export const store = {
  pointsStore: '积分商店',
  exchangeRecord: '兑换记录',
  integral: '积分',
  productTypes: '商品类型',
  RemainingNum: '还剩',
  Revise: '修改',
  NotEnoughPoints: '积分不足',
  exchangeLimit: '今日兑换已上限',
  NotEnoughGoods: '今日商品不足',
  productExchangeLimit: '该商品已达兑换上限',
}

//log
export const log = {
  exchangeLog: '兑换日志',
  sellingPrice: '销售价格',
  giftCode: '礼包码',
  status: '状态',
  shippingTime: '发货时间',
  DataCompleted: '所有数据已加载',
  unpaid: '未支付',
  WaitDelivery: '待发货',
  shipped: '已发货',
}

//goodsDetail
export const goodsDetail = {
  gift: '礼品',
  sellingPrice: '销售价格',
  RemainingNum: '还剩',
}

//goodsPay
export const goodsPay = {
  game: '游戏',
  receiver: '收货人',
  enterReceiver: '请输入收货人的全名',
  phone: '电话',
  enterPhone: '请输入您的电话号码',
  address: '地址',
  enterAddress: '请输入您的地址',
  payType: '支付方式',
  confirmProduct: '确认兑换此商品？',
  cancel: '取消',
  confirm: '确认',
  enterCompleteInfo: '请输入完整内容信息',
  chooseProduce: '请选择商品',
}

//giftCode
export const giftCode = {
  confirm: '确认',
  enterCard: '请输入卡号',
  enterCompleteInfo: '请输入完整内容信息',
}

//coin
export const coin = {
  walletBalance: '钱包余额',
  coinBalance: 'Coin 余额：',
  topUp: '确定',
}

//orderList
export const orderList = {
  purchaseLog: '购买日志',
  purchasePrice: '购买金额',
  giftAmount: '赠送金额',
  createTime: '创建时间',
}

//codeBind
export const codeBind = {
  beta: '内测资格',
  code: '验证码',
  enterCode: '请输入验证码',
  activation: '激活',
}

//VipList
export const VipList = {
  need: '下一级升级所需：{exp} EXP',
  superlative: '已达到最高等级',
  exclusivePrivileges: '专属特权',
  growthLevel: '成长等级',
  dec1: '玩家每次充值后，会按照每0.01USD=1经验值的形式发放相对应的经验值到玩家账户上，根据以上表格，获取到相应的经验值后便可达到相对应的VIP等级，玩家同时可以享有当前VIP等级的所有会员权益。',
  dec2: '同时，以半年为一个周期，累计充值无法达到保险级经验的账户，也会扣除相应的经验值，存在降低VIP等级的情况，具体经验值情况如下表所示：',
  table1: 'VIP等级',
  table2: '扣除经验/半年',
  table3: '保级累充/半年',
  receive: '收到',
  time: '次',
  name1: '签到积分',
  name2: 'VIP礼包',
  name3: 'VIP券',
  name4: '绑定',
  name5: '充值奖励',
  name6: '被盗找回',
  name7: '解绑',
  name8: '遇见福利',
  name9: '误操作恢复',
}


//sign in 签到积分
export const signIn = {
  title: '签到积分',
  dec: '玩家在平台签到后，可以获取相应的积分奖励，不同的VIP等级可以获得不同的积分奖励，通过积分可以兑换相对应的礼品，奖励积分如下：',
  table1: 'VIP等级',
  table2: '签到奖励积分',
}

//vipGift VIP礼包
export const vipGift = {
  vipGift: 'Vip礼品',
  chooseServer: '选择服务器',
  chooseRole: '选择角色',
  receivingGifts: '接受礼物',
  gift: '礼品',
  confirmUse: '确定使用 :',
  usePrivileges: '使用vip特权礼包给这个角色？',
  chooseServerAndRole: '请选择角色和服务器',
  dec1: '礼包权益玩家每月仅可领取一次，不同VIP等级的玩家可以领取不同的VIP礼包，该礼包通过手动领取的方式，领取之后会以以下其中一种形式进行发放：',
  dec2: '1：自动发放到我的礼包列表中，玩家可以复制礼包码。在对应的游戏中兑换等价值的奖励。',
  dec3: '2：直接通过游戏发放到对应的领取角色中。',
  table1: 'VIP等级',
  table2: 'VIP礼包',
  tableText: '礼包特权',
}

//vipCoupon VIP券
export const vipCoupon = {
  vipCoupon: 'VIP优惠券',
  dec1: '当每次升级VIP等级时，系统将自动发放相对应的充值优惠券到玩家的账户中，VIP等级对应的优惠券如下：',
  table1: 'VIP等级',
  table2: 'VIP优惠券',
  tableText1: '无',
  tableText2: '普通满送优惠券礼包',
  tableText3: '高级满送优惠券礼包',
  tableText4: '特级满送优惠券礼包',
  tableText5: '至尊满送优惠券礼包',
  dec2: '普通满送优惠券礼包：',
  dec3: '高级满送优惠券礼包：',
  dec4: '特级满送优惠券礼包：',
  dec5: '至尊满送优惠券礼包：',
  dec2Text: '1usd送10coin*1、2usd送25coin*1',
  dec3Text: '5usd送75coin*1、10usd送180coin*1',
  dec4Text: '20usd送400coin*1、50usd送1100coin*1',
  dec5Text: '100usd送3000coin*1、300usd送10000coin*1',
}

//bind 绑定
export const bind = {
  enterEmail: '请输入您的电子邮件',
  enterCode: '请输入验证码',
  enterPassword: '请输入您的密码',
  enterPasswordAgain: '请重新输入你的密码',
  enterTrueEmail: '请输入正确的邮箱账号！',
  enterNumPassword: '请输入包含字母和数字的6-20个字符的密码',
  differentPassword: '两次输入的密码不相同！',
  sendCode: '发送验证码',
  bindSuccess: '绑定成功',
}

//nap 充值奖励
export const nap = {
  title: '充值奖励',
  dec1: '获得充值档位对应平台币的百分比进行返利',
  dec2:'举例：如购买200张代金券，价值2usd，则返利公式为：2*100*1%=2coin（各VIP等级返利具体如下表）',
  table1: 'VIP等级',
  table2: '额外奖励',
  tableText:'奖励实际支付金额的{num}%',
  tableText1: '无',
  tableText2: '奖励实际支付金额的1%',
  tableText3: '奖励实际支付金额的2%',
  tableText4: '奖励实际支付金额的3%',
}

//retrieve 被盗找回
export const retrieve = {
  title: '被盗找回',
  dec1: '玩家在MGOL账号VIP等级达到V6及以上的时候，享有被盗找回的会员权益，具体VIP等级对应的被盗找回权益如下：',
  table1: 'VIP等级',
  table2: '找回次数',
  tableText1: '1次/半年',
}

//unbind 解绑
export const unbind = {
  unbind: '解除绑定',
  sendCode: '发送验证码',
  account: '账号',
  source: '来源',
  operate: '操作',
  unbindAccount: '此操作将解绑您的账号，是否继续？',
  cancelUnbind: '取消解绑',
  unbindSuccess: '解绑成功',
  cancelUnbindSuccess: '已取消解绑',
}

//meet 遇见福利
export const meet = {
  title: '遇见福利',
  dec1: '玩家注册平台账号，是您与平台相遇的日子，当您VIP等级达到V7及以上，每年的当天系统将会自动为您的账户上发送相遇福利，VIP所对应的相遇福利如下：',
  time: '相遇时间：',
  table1: 'VIP等级',
  table2: '遇见福利礼包',
  tableText: '充值{num}usd送{coin}coin优惠券'
}

//recovery 误操作恢复
export const recovery = {
  title: '误操作恢复',
  dec1: '玩家在MGOL账号VIP等级达到V7及以上的时候，享有被盗找回的会员权益，具体VIP等级对应的被盗找回权益如下：',
  table1: 'VIP等级',
  table2: '恢复次数',
  tableText: '次/半年'
}

