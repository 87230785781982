//首页 Landing
export const Landing = {
  Recharge: 'Top Up Sekarang',
  chooseGame: 'Silakan pilih gim untuk top up.',
  enterGameName: 'Silakan masukkan nama gim.',
  gameList: 'Daftar Gim',
  enter: 'Masuk Sekarang',
  more: 'Lainnya',
  problem: 'Kenapa harus top up melalui metode pembayaran dalam gim?',
  answer1: '1. Event Terbatas: Event seru yang diadakan dalam waktu terbatas.',
  answer2: '2. Keistimewaan VIP: Nikmati hak eksklusif VIP dan dapatkan item yang lebih berlimpah.',
  answer3: '3. CS Khusus: Nikmati layanan CS eksklusif yang akan menangani masalahmu dengan lebih cepat.',
  answer4: '4. Event Diskon: Event Voucer yang diadakan di saat-saat tertentu. Dapatkan lebih banyak token atau beli item dengan harga lebih murah.',
  answer5: '5. Metode Top Up Beragam: Top up jadi lebih mudah dengan lebih banyak metode yang tersedia.',
  answer1_title: 'Event Terbatas',
  answer2_title: 'Keistimewaan VIP',
  answer3_title: 'CS Khusus',
  answer4_title: 'Event Diskon',
  answer5_title: 'Metode Top Up Beragam',
  answer1_dec: 'Event seru yang diadakan dalam waktu terbatas.',
  answer2_dec: 'Nikmati hak eksklusif VIP dan dapatkan item yang lebih berlimpah.',
  answer3_dec: 'Nikmati layanan CS eksklusif yang akan menangani masalahmu dengan lebih cepat.',
  answer4_dec: 'Event Voucer yang diadakan di saat-saat tertentu. Dapatkan lebih banyak token atau beli item dengan harga lebih murah.',
  answer5_dec: 'Top up jadi lebih mudah dengan lebih banyak metode yang tersedia.',
  close: 'Tutup',
  allGame: 'Semua Gim'
}

//底部信息 Footer
export const Footer = {
  mgolFooter: 'Nomor izin penyediaan layanan gim daring G1: 283/GP-BTTTT. Tanggal: 7 Agustus 2023.',
  mocFooter: 'Nomor izin penyediaan layanan gim internet G1: 463/GP-BTTTT. Tanggal: 28 Oktober 2019.',
  address: 'Alamat:'

}

//导航栏信息 info
export const Info = {
  return: 'Ke Beranda',
  store: 'Toko Poin',
  order: 'Pesanan',
  unlock: 'Buka',
  exit: 'Keluar',
  cancel: 'Batal',
  napProblem:'Pertanyaan Pengisian Ulang'
}

//登录页 Login
export const Login = {
  gameId: 'Login Akun',
  or: 'atau gunakan',
  regAccount: 'Registrasi',
  emailAccount: 'Email',
  phoneAccount: 'Ponsel',
  password: 'Kata Sandi',
  confirm: 'Setuju',
  enterEmail: 'Silakan masukkan email.',
  enterCode: 'Silakan masukkan kode verifikasi.',
  enterPassword: 'Silakan masukkan kata sandi.',
  enterPasswordAgain: 'Silakan masukkan ulang kata sandi.',
  retrunLogin: 'Layar Login',
  enterTrueEmail: 'Silakan masukkan akun email yang sesuai!',
  enterNumPassword: 'Silakan masukkan kata sandi yang terdiri dari 6-20 karakter gabungan huruf dan angka.',
  differentPassword: 'Kata sandi yang dimasukkan tidak sesuai!',
  sendCode: 'Kirim Kode Verifikasi',
  enterAccount: 'Silakan masukkan data akun.',
  enterTrueEmail: 'Silakan masukkan email yang sesuai.',
  enterTruePhone: 'Silakan masukkan nomor ponsel yang sesuai.',
  loginSuccess: 'Login berhasil!',
  resend: 'Kirim Ulang',
  reg: 'Registrasi',
}


//详情页 consume
export const consume = {
  noData: 'Data belum tersedia.',
  gameId: 'Pilih karakter',
  chooseServer: 'Pilih Server',
  chooseRole: 'Pilih Karakter',
  Server: 'Server:',
  roleId: 'ID Karakter:',
  roleName: 'Nama Karakter:',
  chooseOtherRole: 'Karakter Lainya',
  ChooseCombo: 'Pilih barang',
  returen: 'Kembali:',
  choosePayMethod: 'Pilih Metode Pembayaran',
  remaining: 'Tersisa',
  extraGift: 'Bonus Tambahan',
  next: 'Berikutnya',
  confirmInfo: 'Konfirmasi Data',
  pay: 'Bayar',
  selectCoupon: 'Pilih Kupon',
  canUseCoupon: '{num} Kupon',
  notUse: 'Kosong',
  price: 'Harga',
  btnRemark: 'Dengan menekan tombol "Bayar Sekarang", kamu dianggap setuju untuk menjalankan transaksi ini. Semua pembayaran tidak dapat dibatalkan atau dikembalikan.',
  limitedTimeEvent: '{num} kali kesempatan partisipasi gratis dalam waktu terbatas (Coin dengan nominal terbesar). Voucer tidak dapat digunakan untuk berpartisipasi dalam event.""',
  currency: 'Token',
  special: 'Spesial',
  enterAccount: 'Silakan masukkan data akun.',
  enterPassword: 'Silakan masukkan kata sandi.',
  step1: 'Langkah 1/4: ID Akun',
  step2: 'Langkah 2/4: Pilih Paket',
  step3: 'Langkah 3/4: Pilih Metode Pembayaran',
  step4: 'Langkah 4/4: Konfirmasi Data',
  step2_1: 'Langkah 1/3：Pilih satu negara',
  step3_1: 'Langkah 2/3: Pilih Metode Pembayaran',
  step4_1: 'Langkah 3/3: Konfirmasi Data',
  insufficientBalance: 'Saldo tidak mencukupi.',
  MinDeposit: 'Pembayaran minimum {money}',
  validityPeriod: 'Berlaku',
  availableDates: 'Periode',
  deliver1: 'Top Up {money} USD Bonus {gift} Coin',
  deliver2: 'Top Up {money} USD Diskon {gift}%',
  deliver3: 'Top Up {money} USD Diskon {gift}',
  paySuccess: 'Pembayaran berhasil!',
  revise: 'Ubah',
  enterCard: 'Masukkan kode kartu.',
  cardRecharge: 'Top Up Kartu',
  channelRebate: 'Rabat Pembayaran:',
  VIPRebate: 'Rabat VIP:',
  couponRebate: 'Rabat Voucer:',
  totalRebate: 'Total Rabat:',
  remainingNum: 'Tersisa {num} kali',
  limitNum: 'Hari ini hanya dapat digunakan {num} kali.',
  notUseCoupon: 'Voucer tidak tersedia.',
  reselect: 'Voucer tidak mendukung metode pembayaran ini. Silakan pilih kembali!',
  channel: 'Metode Pembayaran:',
  use: "Pakai",
  chooseCountry: 'Pilih satu negara',
  payCountry:'Wilayah pembayaran',
  napRemark:'Selama proses pengisian ulang, jika anda mengalami masalah apa pun, anda dapat memberikan umpan balik melalui tombol "Pertanyaan Pengisian Ulang" di bagian atas.',
  autoLogin:'Beli Kupon',
  maintain:'Dalam pemeliharaan. Silakan gunakan metode lainnya.',
}

//AtmResult 和 purchaseResult
export const result = {
  paySuccess: 'Pembayaran berhasil!',
  payFail: 'Pembayaran gagal!',
  orderNo: 'Nomor Pesanan',
  payMoney: 'Nilai Pembayaran',
  giftAmount: 'Nilai Bonus',
  return: 'Kembali'
}

//首页头 page
export const page = {
  enterGameName: 'Silakan masukkan nama gim.',
}

//gate
export const gate = {
  serialNumber: 'Nomor Seri:',
  enterSerialNumber: 'Masukkan nomor seri:',
  cardNumber: 'Nomor Kartu',
  enterCardNumber: 'Masukkan nomor kartu.',
  topUp: 'Top Up Kartu',
  topUpSuccess: 'Top up berhasil!',
}

//unlock
export const unlock = {
  confirm: 'Setuju',
}

//store
export const store = {
  pointsStore: 'Toko Poin',
  exchangeRecord: 'Riwayat Penukaran',
  integral: 'Poin',
  productTypes: 'Jenis Produk',
  RemainingNum: 'Tersisa',
  Revise: 'Ubah',
  NotEnoughPoints: 'Poin tidak mencukupi.',
  exchangeLimit: 'Sudah mencapai batas penukaran hari ini.',
  NotEnoughGoods: 'Produk tidak mencukupi.',
  productExchangeLimit: 'Sudah mencapai batas penukaran.',
}

//log
export const log = {
  exchangeLog: 'Log Penukaran',
  sellingPrice: 'Harga Jual',
  giftCode: 'Kode Hadiah',
  status: 'Status',
  shippingTime: 'Waktu Pengiriman',
  DataCompleted: 'Semua data sudah dimuat.',
  unpaid: 'Menunggu Pembayaran',
  WaitDelivery: 'Menunggu Pengiriman',
  shipped: 'Terkirim',
}

//goodsDetail
export const goodsDetail = {
  gift: 'Hadiah',
  sellingPrice: 'Harga Jual',
  RemainingNum: 'Tersisa',
}

//goodsPay
export const goodsPay = {
  game: 'Gim',
  receiver: 'Penerima',
  enterReceiver: 'Silakan masukkan nama lengkap penerima.',
  phone: 'Telepon',
  enterPhone: 'Silakan masukkan nomor teleponmu.',
  address: 'Alamat',
  enterAddress: 'Silakan masukkan alamatmu.',
  payType: 'Metode Pembayaran',
  confirmProduct: 'Tukarkan produk ini?',
  cancel: 'Batal',
  confirm: 'Setuju',
  enterCompleteInfo: 'Silakan masukkan secara lengkap.',
  chooseProduce: 'Silakan pilih produk.',
}

//giftCode
export const giftCode = {
  confirm: 'Setuju',
  enterCard: 'Silakan masukkan nomor kartu.',
  enterCompleteInfo: 'Silakan masukkan secara lengkap.',
}

//coin
export const coin = {
  walletBalance: 'Saldo Dompet',
  coinBalance: 'Saldo Coin:',
  topUp: 'Top Up Kartu',
}

//orderList
export const orderList = {
  purchaseLog: 'Log Pembelian',
  purchasePrice: 'Nilai Pembelian',
  giftAmount: 'Nilai Bonus',
  createTime: 'Waktu Pembuatan',
}

//codeBind
export const codeBind = {
  beta: 'Kesempatan CBT',
  code: 'Kode Verifikasi',
  enterCode: 'Silakan masukkan kode verifikasi.',
  activation: 'Aktifkan',
}

//VipList
export const VipList = {
  need: 'Untuk ke level berikutnya: {exp} EXP',
  superlative: 'Mencapai level tertinggi.',
  exclusivePrivileges: 'Keistimewaan Eksklusif',
  growthLevel: 'Level Pertumbuhan',
  dec1: 'Setelah top up, EXP akan dikirimkan ke akun pemain dengan perbandingan 0.01USD=1 EXP. Setelah mendapatkan EXP, pemain bisa mencapai level VIP sesuai dengan tabel di atas dan mendapat semua hak keanggotaan dalam level tersebut.',
  dec2: 'Selain itu, akun yang jumlah top up dalam setiap semesternya tidak mencapai jumlah EXP penjamin level akan terkena pemotongan EXP dan penurunan level VIP sesuai tabel di bawah ini:',
  table1: 'Level VIP',
  table2: 'Pemotongan EXP/semester',
  table3: 'Top up penjamin level/semester',
  receive: 'Terima',
  time: 'kali',
  name1: 'Poin Sign In',
  name2: 'Paket VIP',
  name3: 'Voucer VIP',
  name4: 'Tautkan',
  name5: 'Hadiah Top Up',
  name6: 'Pemulihan Akun',
  name7: 'Batalkan Penautan',
  name8: 'Tunjangan Pertemuan',
  name9: 'Pemulihan Tindakan',
}

//sign in 签到积分
export const signIn = {
  title: 'Titik kehadiran',
  dec: 'Pemain dari pendaftaran, Menerima poin yang sesuai, level VIP yang berbeda dapat menerima poin tambahan, Gunakan poin untuk menukarkan hadiah yang sesuai, Poin hadiah spesifik adalah sebagai berikut:',
  table1: 'tingkat VIP',
  table2: 'Poin bonus dari kehadiran',
}

//vipGift VIP礼包
export const vipGift = {
  vipGift: 'Hadiah VIP',
  chooseServer: 'Pilih Server',
  chooseRole: 'Pilih Karakter',
  receivingGifts: 'Terima Hadiah',
  gift: 'Hadiah',
  confirmUse: 'Konfirmasi Pemakaian:',
  usePrivileges: 'Pakai Paket Keistimewaan VIP pada karakter ini?',
  chooseServerAndRole: 'Silakan pilih karakter dan server.',
  dec1: 'Pemain hanya dapat mengambil paket satu kali setiap bulan. Pemain dengan level VIP yang berbeda akan mendapatkan Paket VIP yang berbeda. Paket ini harus diambil secara manual dan akan dibagikan dengan salah satu metode di bawah ini:',
  dec2: '1：Otomatis dikirimkan ke dalam daftar paket. Pemain bisa menyalin gift code dan menukarkannya dengan hadiah yang setara di dalam gim.',
  dec3: '2：Langsung dikirim ke karakter yang sesuai di dalam gim.',
  table1: 'Level VIP',
  table2: 'Paket VIP',
  tableText: 'Keistimewaan Paket',
}

//vipCoupon
export const vipCoupon = {
  vipCoupon: 'Voucer VIP',
  dec1: 'Setiap meningkatkan level VIP, sistem akan mengirimkan voucer top up ke akun pemain dengan ketentuan sebagai berikut:',
  table1: 'Level VIP',
  table2: 'Voucer VIP',
  tableText1: 'Kosong',
  tableText2: 'Paket Voucer Bonus Biasa',
  tableText3: 'Paket Voucer Bonus Lanjutan',
  tableText4: 'Paket Voucer Bonus Spesial',
  tableText5: 'Paket Voucer Bonus Ultima',
  dec2: 'Paket Voucer Bonus Biasa:',
  dec3: 'Paket Voucer Bonus Lanjutan:',
  dec4: 'Paket Voucer Bonus Spesial:',
  dec5: 'Paket Voucer Bonus Ultima:',
  dec2Text: '1 usd bonus 10coin x1, 2usd bonus 25coin x1',
  dec3Text: '5 usd bonus 75coin x1, 10usd bonus 180coin x1',
  dec4Text: '20 usd bonus 400coin x1, 50usd bonus 1100coin x1',
  dec5Text: '100 usd bonus 3000coin x1, 300usd bonus 10000coin x1',
}


//bind 绑定
export const bind = {
  enterEmail: 'Silakan masukkan email.',
  enterCode: 'Silakan masukkan kode verifikasi.',
  enterPassword: 'Silakan masukkan kata sandi.',
  enterPasswordAgain: 'Silakan masukkan ulang kata sandi.',
  enterTrueEmail: 'Silakan masukkan akun email yang sesuai!',
  enterNumPassword: 'Silakan masukkan kata sandi yang terdiri dari 6-20 karakter gabungan huruf dan angka.',
  differentPassword: 'Kata sandi yang dimasukkan tidak sesuai!',
  sendCode: 'Kirim Kode Verifikasi',
  bindSuccess: 'Penautan berhasil!',
}

//nap 充值奖励
export const nap = {
  title: 'Hadiah Top Up',
  dec1: 'Mendapat cashback berupa coin sebesar persentase tertentu dari jumlah top up.',
  dec2:'Contoh: Pembelian 200 Voucer senilai 2 USD, rumus perhitungan cashback: 2*100*1%= 2 Coin (Di bawah ini adalah daftar cashback untuk masing-masing level VIP)',
  table1: 'Level VIP',
  table2: 'Hadiah Tambahan',
  tableText: 'Berhadiah {num}% dari nominal pembayaran',
  tableText1: 'Kosong',
  tableText2: 'Berhadiah 1% dari nominal pembayaran',
  tableText3: 'Berhadiah 2% dari nominal pembayaran',
  tableText4: 'Berhadiah 3% dari nominal pembayaran',
}

//retrieve 被盗找回
export const retrieve = {
  title: 'Pemulihan Akun',
  dec1: 'Pemain dengan akun MGOL yang mencapai VIP Lv. 6 ke atas bisa mendapatkan hak pemulihan akun dengan perincian sebagai berikut:',
  table1: 'Level VIP',
  table2: 'Kesempatan Pemulihan',
  tableText1: '1 kali/semester',
}


//unbind
export const unbind = {
  unbind: 'Batalkan Penautan',
  sendCode: 'Kirim Kode Verifikasi',
  account: 'Akun',
  source: 'Sumber',
  operate: 'Tindakan',
  unbindAccount: 'Tindakan ini akan membatalkan penautan. Tetap lanjutkan?',
  cancelUnbind: 'Batal',
  unbindSuccess: 'Pembatalan penautan berhasil!',
  cancelUnbindSuccess: 'Pembatalan penautan ditangguhkan.',
}

//meet 遇见福利
export const meet = {
  title: 'Tunjangan Pertemuan',
  dec1: 'Tanggal saat kamu melakukan registrasi adalah tanggal pertemuanmu dengan platform ini. Setelah mencapai VIP Lv. 7 ke atas, sistem akan mengirimkan hadiah di tanggal yang sama setiap tahun dengan perincian sebagai berikut:',
  time: 'Tanggal Pertemuan:',
  table1: 'Level VIP',
  table2: 'Paket Tunjangan Pertemuan',
  tableText: 'Isi ulang {num}usd untuk mendapatkan kupon koin {coin}'
}

//recovery 误操作恢复
export const recovery = {
  title: 'Pemulihan Tindakan',
  dec1: 'Pemain dengan akun MGOL yang mencapai VIP Lv. 6 ke atas bisa mendapatkan hak pemulihan akun dengan perincian sebagai berikut:',
  table1: 'Level VIP',
  table2: 'Kesempatan Pemulihan',
  tableText: 'kali/semester'
}



