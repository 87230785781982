//首页 Landing
export const Landing = {
  Recharge: 'Top Up Now',
  chooseGame: 'Please select a game to top up.',
  enterGameName: 'Please enter a game name.',
  gameList: 'Game List',
  enter: 'Enter Now',
  more: 'View More',
  problem: "What's the benefit of using game payment channels for top-up?",
  answer1: '1. Limited-time Events: Participate in various limited-time events.',
  answer2: '2. VIP Privileges: Enjoy exclusive VIP privileges and receive more in-game item rewards.',
  answer3: '3. Exclusive Customer Service: Access exclusive VIP customer service to address your concerns.',
  answer4: '4. Coupons&Discounts: Receive coupons to earn platform coins or buy in-game items at discounted prices.',
  answer5: '5. More Top-up Options: Select from multiple methods for an easy top.',
  answer1_title: 'Limited-time Events',
  answer2_title: 'VIP Privileges',
  answer3_title: 'Exclusive Customer Service',
  answer4_title: 'Coupons&Discounts',
  answer5_title: 'More Top-up Options',
  answer1_dec: 'Participate in various limited-time events.',
  answer2_dec: 'Enjoy exclusive VIP privileges and receive more in-game item rewards.',
  answer3_dec: 'Access exclusive VIP customer service to address your concerns.',
  answer4_dec: 'Receive coupons to earn platform coins or buy in-game items at discounted prices.',
  answer5_dec: 'Select from multiple methods for an easy top.',
  close: 'Close',
  allGame: 'All Games',
}

//底部信息 Footer
export const Footer = {
  mgolFooter: 'License number for providing G1 online game services: 283/GP-BTTTT, dated August 7, 2023',
  mocFooter: 'License number for providing G1 online game services: 463/GP-BTTTT, dated October 28, 2019',
  address: 'Address:'

}

//导航栏信息 info
export const Info = {
  return: 'Return to Homepage',
  store: 'Points Store',
  order: 'Order',
  unlock: 'Unlock',
  exit: 'Exit',
  cancel: 'Cancel',
  napProblem:'Top-up issues'
}

//登录页 Login
export const Login = {
  gameId: 'Account Login',
  or: 'or use',
  regAccount: 'Register',
  emailAccount: 'Email Account',
  phoneAccount: 'Phone Account',
  password: 'Password',
  confirm: 'Confirm',
  enterEmail: 'Please enter your email address.',
  enterCode: 'Please enter the verification code.',
  enterPassword: 'Please enter your password.',
  enterPasswordAgain: 'Please re-enter your password.',
  retrunLogin: 'Return to Login',
  enterTrueEmail: 'Please enter a valid email address!',
  enterNumPassword: 'Please enter a password of 6-20 characters containing letters and numbers.',
  differentPassword: 'The passwords entered do not match!',
  sendCode: 'Send Verification Code',
  enterAccount: 'Please enter your account info.',
  enterTrueEmail: 'Please enter a valid email address.',
  enterTruePhone: 'Please enter a valid phone number.',
  loginSuccess: 'Login Successful',
  resend: 'Resend',
  reg: 'Register'
}


//详情页 consume
export const consume = {
  noData: 'No data available',
  gameId: 'Choose role',
  chooseServer: 'Select Server',
  chooseRole: 'Select Character',
  Server: 'Server:',
  roleId: 'Character ID:',
  roleName: 'Character Name:',
  chooseOtherRole: 'Change Character',
  ChooseCombo: 'Choose a product',
  return: 'Return',
  choosePayMethod: 'Select Payment Method',
  remaining: 'Remaining',
  extraGift: 'Bonus',
  next: 'Next',
  confirmInfo: 'Confirm Info',
  pay: 'Pay',
  selectCoupon: 'Select Coupon',
  canUseCoupon: '{num} Coupon',
  notUse: 'None',
  price: 'Price',
  btnRemark: 'By tapping "Pay Now," you agree that this transaction is non-refundable and non-cancellable.',
  limitedTimeEvent: 'You have {num} free attempts (maximum currency usable for top-ups). Coupons cannot be used for this event.',
  currency: 'Currency',
  special: 'Special',
  enterAccount: 'Please enter your account info.',
  enterPassword: 'Please enter your password.',
  step1: 'Step 1/4: Game Account',
  step2: 'Step 2/4: Choose Package',
  step3: 'Step 3/4: Select Payment Method',
  step4: 'Step 4/4: Confirm Info',
  step2_1: 'Step 1/3：Choose a country',
  step3_1: 'Step 2/3: Select Payment Method',
  step4_1: 'Step 3/3: Confirm Info',
  insufficientBalance: 'Insufficient Balance',
  MinDeposit: 'Minimum Payment Amount: {money}',
  validityPeriod: 'Validity Period',
  availableDates: 'Available Dates',
  deliver1: 'Spend {money} USD, Receive {gift} Coin',
  deliver2: 'Spend {money} USD, Save {gift}%',
  deliver3: 'Spend {money} USD, Save {gift}',
  paySuccess: 'Payment Successful',
  revise: 'Edit',
  enterCard: 'Enter Card Code',
  cardRecharge: 'Top Up with Card',
  channelRebate: 'Channel Rebate:',
  VIPRebate: 'VIP Rebate:',
  couponRebate: 'Coupon Rebate:',
  totalRebate: 'Total Rebate Amount:',
  remainingNum: 'Remaining Attempts: {num}',
  limitNum: 'You can attempt {num} times at most today.',
  notUseCoupon: 'No coupons available.',
  reselect: 'This coupon cannot be used for this payment method. Please re-select!',
  channel: 'Payment Channel:',
  use: 'Use',
  chooseCountry:'Choose a country',
  payCountry:'Region',
  napRemark:'During the recharge process, if you encounter any problems, you can give feedback through the "Top-up issues" button at the top.',
  autoLogin:'Purchase Voucher',
  maintain:'Currently under maintenance. Please try a different payment method.',
}

//AtmResult 和 purchaseResult
export const result = {
  paySuccess: 'Payment Successful',
  payFail: 'Payment Unsuccessful',
  orderNo: 'Order Number',
  payMoney: 'Payment Amount',
  giftAmount: 'Bonus Amount',
  return: 'Return',
}

//首页头 page
export const page = {
  enterGameName: 'Please enter a game name.',
}

//gate
export const gate = {
  serialNumber: 'Serial Number:',
  enterSerialNumber: 'Enter Serial Number:',
  cardNumber: 'Card Number',
  enterCardNumber: 'Enter Card Number',
  topUp: 'Top Up with Card',
  topUpSuccess: 'Top-up Successful',
}

//unlock
export const unlock = {
  confirm: 'Confirm',
}

//store
export const store = {
  pointsStore: 'Points Store',
  exchangeRecord: 'Exchange History',
  integral: 'Points',
  productTypes: 'Item Type',
  RemainingNum: 'Remaining',
  Revise: 'Edit',
  NotEnoughPoints: 'Insufficient Points',
  exchangeLimit: 'Exchange limit reached today',
  NotEnoughGoods: 'Insufficient stock today',
  productExchangeLimit: 'Exchange limit reached for this item',
}

//log
export const log = {
  exchangeLog: 'Exchange Log',
  sellingPrice: 'Selling Price',
  giftCode: 'Gift Code',
  status: 'Status',
  shippingTime: 'Shipping Time',
  DataCompleted: 'All data has been loaded.',
  unpaid: 'Unpaid',
  WaitDelivery: 'To Be Shipped',
  shipped: 'Shipped'
}

//goodsDetail
export const goodsDetail = {
  gift: 'Gift',
  sellingPrice: 'Selling Price',
  RemainingNum: 'Remaining',
}

//goodsPay
export const goodsPay = {
  game: 'Game',
  receiver: 'Recipient',
  enterReceiver: "Please enter the recipient's full name.",
  phone: 'Phone',
  enterPhone: 'Please enter your phone number.',
  address: 'Address',
  enterAddress: 'Please enter your address.',
  payType: 'Payment Method',
  confirmProduct: 'Please confirm your selection.',
  cancel: 'Cancel',
  confirm: 'Confirm',
  enterCompleteInfo: 'Please enter complete info.',
  chooseProduce: 'Select Item',

}

//giftCode
export const giftCode = {
  confirm: 'Confirm',
  enterCard: 'Please enter a card code.',
  enterCompleteInfo: 'Please enter complete info.',
}

//coin
export const coin = {
  walletBalance: 'Wallet Balance',
  coinBalance: 'Coin Balance:',
  topUp: 'Top Up with Card',
}

//orderList
export const orderList = {
  purchaseLog: 'Purchase History',
  purchasePrice: 'Purchase Amount',
  giftAmount: 'Bonus Amount',
  createTime: 'Creation Time',
}

//codeBind
export const codeBind = {
  beta: 'Beta Access',
  code: 'Verification Code',
  enterCode: 'Please enter the verification code.',
  activation: 'Activate',
}

//VipList
export const VipList = {
  need: 'EXP Required for Next Level: {exp}',
  superlative: 'Max level reached',
  exclusivePrivileges: 'Exclusive Privileges',
  growthLevel: 'Growth Level',
  dec1: 'After each top-up, your account will receive EXP based on the top-up amount, at a rate of 0.01USD=1 EXP. Your VIP level is determined by the total EXP accumulated. Refer to the chart above for details. Once you reach a VIP level, you can enjoy all the corresponding benefits.',
  dec2: 'Every six months, accounts that fail to accumulate the minimum required EXP to maintain their current VIP level will have their EXP deducted, potentially resulting in a decrease in VIP level. Refer to the chart below for deduction details.',
  table1: 'VIP Level',
  table2: 'EXP Deduction per Half Year',
  table3: 'Min Top-up Amount per Half Year',
  receive: 'Receive',
  time: 'Attempts',
  name1: 'Sign-in Points',
  name2: 'VIP Pack',
  name3: 'VIP Voucher',
  name4: 'Link',
  name5: 'Top-up Reward',
  name6: 'Recover Stolen Account',
  name7: 'Unlink',
  name8: 'Encounter Benefits',
  name9: 'Misoperation Fix',
}

//sign in 签到积分
export const signIn = {
  title: 'Sign-in Points',
  dec: 'After signing in on the platform, you can earn corresponding points rewards. Different VIP levels offer different points rewards. Points can be exchanged for gifts. The rewarded points are as follows:',
  table1: 'VIP Level',
  table2: 'Sign-in Point Reward',
}

//vipGift VIP礼包
export const vipGift = {
  vipGift: 'VIP Gift',
  chooseServer: 'Select Server',
  chooseRole: 'Select Character',
  receivingGifts: 'Accept Gift',
  gift: 'Gift',
  confirmUse: 'Confirm Usage:',
  usePrivileges: 'Use the VIP Privilege Pack for this character?',
  chooseServerAndRole: 'Select Character and Server',
  dec1: 'You can claim a VIP Pack once per month. Different VIP levels offer different VIP Packs, which must be manually claimed and are then distributed in one of the following ways:',
  dec2: '1. Automatically added to the "Your Packs" section, allowing you to copy the pack code and redeem equivalent rewards in the corresponding game.',
  dec3: '2. Directly delivered to your corresponding in-game character.',
  table1: 'VIP Level',
  table2: 'VIP Pack',
  tableText: 'Pack Privilege',
}

//vipCoupon VIP券
export const vipCoupon = {
  vipCoupon: 'VIP Coupon',
  dec1: 'Upon each VIP level upgrade, your account will receive corresponding top-up coupons. The coupons corresponding to VIP levels are as follows:',
  table1: 'VIP Level',
  table2: 'VIP Coupon',
  tableText1: 'None',
  tableText2: 'Basic Spending Bonus Pack',
  tableText3: 'Senior Spending Bonus Pack',
  tableText4: 'Super Spending Bonus Pack',
  tableText5: 'Supreme Spending Bonus Pack',
  dec2: 'Basic Spending Bonus Pack',
  dec3: 'Senior Spending Bonus Pack',
  dec4: 'Super Spending Bonus Pack',
  dec5: 'Supreme Spending Bonus Pack',
  dec2Text: 'Spend 1usd, Receive 10coin x1; Spend 2usd, Receive 25coin x1',
  dec3Text: 'Spend 5usd, Receive 75coin x1; Spend 10usd, Receive 180coin x1',
  dec4Text: 'Spend 20usd, Receive 400coin x1; Spend 50usd, Receive 1100coin x1',
  dec5Text: 'Spend 100usd, Receive 3000coin x1; Spend 300usd, Receive 10000coin x1',
}



//bind 绑定
export const bind = {
  enterEmail: 'Please enter your email address.',
  enterCode: 'Please enter the verification code.',
  enterPassword: 'Please enter your password.',
  enterPasswordAgain: 'Please re-enter your password.',
  enterTrueEmail: 'Please enter a valid email address!',
  enterNumPassword: 'Please enter a password of 6-20 characters containing letters and numbers.',
  differentPassword: 'The passwords entered do not match!',
  sendCode: 'Send Verification Code',
  bindSuccess: 'Linking Successful',
}

//nap 充值奖励
export const nap = {
  title: 'Top-up Reward',
  dec1: "Platform Coin rebates are based on the tier of your top-up.",
  dec2:'For example, if you purchase 200 vouchers, each valued at 2 USD, your rebate is: 2 x 100 x 1% = 2 coins (specific rebate rates for each VIP level are outlined in the table below).',
  table1: 'VIP Level',
  table2: 'Extra Reward',
  tableText: 'The reward equals {num}% of the top-up amount.',
  tableText1: 'None',
  tableText2: 'The reward equals 1% of the top-up amount.',
  tableText3: 'The reward equals 2% of the top-up amount.',
  tableText4: 'The reward equals 3% of the top-up amount.',
}

//retrieve 被盗找回
export const retrieve = {
  title: 'Recover Stolen Account',
  dec1: "When your MGOL account's VIP membership reaches Level 6 or higher, you can enjoy the benefit of recovering stolen assets. The specific recovery benefits corresponding to VIP levels are as follows:",
  table1: 'VIP Level',
  table2: 'Recovery Attempts',
  tableText1: '1 Attempt per Half Year',
}

//unbind 解绑
export const unbind = {
  unbind: 'Unlink',
  sendCode: 'Send Verification Code',
  account: 'Account',
  source: 'Source',
  operate: 'Action',
  unbindAccount: 'This action will unlink your account. Continue?',
  cancelUnbind: 'Cancel',
  unbindSuccess: 'Unlinking Successful',
  cancelUnbindSuccess: 'Unlinking Canceled',
}

//meet 遇见福利
export const meet = {
  title: 'Encounter Benefits',
  dec1: 'The day you register an account on the platform marks the beginning of your journey with us. Once your VIP membership reaches Level 7 or higher, the system will automatically send Encounter Benefits to your account on the anniversary of your registration each year. The encounter benefits corresponding to VIP levels are as follows:',
  time: 'Registration Time:',
  table1: 'VIP Level',
  table2: 'Encounter Benefit Pack',
  tableText: 'Recharge {num}usd to get {coin}coin coupon'
}

//recovery 误操作恢复
export const recovery = {
 title:'Misoperation Fix',
  dec1:"When your MGOL account's VIP membership reaches Level 7 or higher, you can enjoy the benefit of recovering stolen assets. The specific recovery benefits corresponding to VIP levels are as follows:",
  table1:'VIP Level',
  table2:'Fix Attempts',
  tableText:'Attempts per Half Year'
}

